import React from 'react'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import { Helmet } from 'react-helmet'
import Promos from 'components/promos'
import Floater from 'components/floating-grid'
import Copy from 'components/category/copy'
import Content from 'components/category/content'
import AdditionalResources from 'components/new/additional-resources'
import Videos from 'components/category/videos'
import Layout from 'components/layout'
import { graphql, Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const items = [
  {
    link: '/lawn-garden/lawn-tractors/100-series/',
    imageId: 'image100Series',
    name: '100 Series',
    alt: 'John Deere 100 Series Riding Lawn Mowers',
  },
  {
    link: '/lawn-garden/lawn-tractors/200-series/',
    imageId: 'image200Series',
    name: '200 Series',
    alt: 'John Deere 200 Series Riding Lawn Mowers',
  },
  {
    link: '/lawn-garden/lawn-tractors/x300-series/',
    imageId: 'x300Image',
    name: 'X300 Series',
    alt: 'John Deere X300 Series Riding Lawn Mowers',
  },
  {
    link: '/lawn-garden/lawn-tractors/x500-series/',
    imageId: 'x500Image',
    name: 'X500 Series',
    alt: 'John Deere X500 Series Riding Lawn Mowers',
  },
  {
    link: '/lawn-garden/lawn-tractors/x700-series/',
    imageId: 'x700Image',
    name: 'X700 Series',
    alt: 'John Deere X700 Series Riding Lawn Mowers',
  },
]

const LawnTractorsPage = ({ data }) => {
  const { allHutsonPromotion, allDeereOffer, heroImage } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => promos.push(node))
  }
  if (allDeereOffer) {
    allDeereOffer.nodes.forEach(node => promos.push(node))
  }
  const gridItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>John Deere Riding Lawn Mowers &amp; Lawn Tractors | Hutson Inc</title>
        <meta
          name='description'
          content="Hutson, your local John Deere dealer, offers a full line up of John Deere riding lawn mowers. Starting as small as 42-inch decks up to 54-inches. The 100 Series is a great for those who are budget conscious and need a mower that's easy to perform maintenance on."
        />
        <meta
          name='keywords'
          content='lawnmowers, mowers, lawn mowers, lawn tractors, riding lawn mowers'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Lawn & Garden Equipment',
                'item': 'https://www.hutsoninc.com/lawn-garden/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Riding Lawn Mowers',
                'item': 'https://www.hutsoninc.com/lawn-garden/lawn-tractors/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='Riding Lawn Mowers' />

      <Content>
        <Floater items={gridItems} />

        <Copy header='John Deere Riding Lawn Mowers'>
          <p>
            Hutson, your local John Deere dealer, offers a full line up of John Deere lawn mowers
            and lawn tractors. Starting as small as 42-inch decks up to 54-inches. The{' '}
            <Link to='/lawn-garden/lawn-tractors/100-series/'>100 Series</Link> is a great for those
            who are budget conscious and need a mower that's easy to perform maintenance on. Need to
            step it up? Get a mower with a stronger transmission and frame with the{' '}
            <Link to='/lawn-garden/lawn-tractors/200-series/'>200 Series</Link>. Do you have up to
            an acre to mow? You might consider stepping it up the{' '}
            <Link to='/lawn-garden/lawn-tractors/x300-series/'>John Deere X300 Series</Link> mowers.
            The heavy-duty{' '}
            <Link to='/lawn-garden/lawn-tractors/x500-series/'>X500 Series lawn tractors</Link> have
            more power to tackle the toughest terrain. Need the biggest riding lawn mower around
            with the most comfort and power? Don't skip over the{' '}
            <Link to='/lawn-garden/lawn-tractors/x700-series/'>John Deere X700 Series</Link>. It's a
            head turner.
          </p>
        </Copy>

        <Promos data={promos} type='Riding Lawn Mower' />

        <Videos
          type='Riding Lawn Mower'
          id='UJe8wFozowc'
          list='PLUz5fj7f_mw87pJRq8hcTk8BZJG1bCRY9'
        />
      </Content>
      <AdditionalResources
        resources={[
          {
            title: 'Product Brochures',
            links: [
              <OutboundLink href='https://www.deere.com/assets/publications/index.html?id=a537a9f8'>
                View Riding Lawn Mowers Brochure
              </OutboundLink>,
            ],
          },
          {
            title: 'Offers & Discounts',
            links: [
              <Link to='/promotions/john-deere/lawn-tractors/'>View Current Promotions</Link>,
            ],
          },
        ]}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query lawnTractorsSubcategoryQuery($subcategory: String = "lawn-tractors") {
    allHutsonPromotion(
      filter: {
        categoryList: { elemMatch: { category: { eq: $subcategory }, type: { eq: "john-deere" } } }
      }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    allDeereOffer(filter: { subcategoryList: { in: [$subcategory] } }, limit: 3) {
      nodes {
        ...DeerePromo
      }
    }
    heroImage: file(relativePath: { eq: "headers/lawn-tractors-subcategory-header.jpg" }) {
      ...FullWidthImage
    }
    image100Series: file(relativePath: { eq: "lawn-and-garden/100-series.jpg" }) {
      ...FloatingGridImage
    }
    image200Series: file(relativePath: { eq: "lawn-and-garden/200-series.jpg" }) {
      ...FloatingGridImage
    }
    x300Image: file(relativePath: { eq: "lawn-and-garden/x300-series.jpg" }) {
      ...FloatingGridImage
    }
    x500Image: file(relativePath: { eq: "lawn-and-garden/x500-series.jpg" }) {
      ...FloatingGridImage
    }
    x700Image: file(relativePath: { eq: "lawn-and-garden/x700-series.jpg" }) {
      ...FloatingGridImage
    }
  }
`

export default LawnTractorsPage
